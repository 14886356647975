"use client";

import Image from "next/image";
import { usePathname } from "next/navigation";

const bussinessPages = ["/our-solutions"];

function HeaderLogo() {
  const pathname = usePathname();

  return (
    <div>
      {pathname.includes("/alba-loyalty") ? (
        <a href="/">
          <Image
            src="/assets/images/travelthru-albaloyalty.svg"
            alt="travelthru-albaloyalty"
            width={160}
            height={40}
            quality={100}
            className="h-auto w-[106.6px] md:w-[160px] object-contain"
          />
        </a>
      ) : bussinessPages.includes(pathname) ? (
        <a href="/">
          <Image
            src="/assets/images/travelthru-business.svg"
            alt="travelthru-business"
            width={160}
            height={40}
            quality={100}
            className=" w-[106.6px] h-auto md:w-[160px] object-contain"
          />
        </a>
      ) : (
        <a href="/" className="relative">
          <Image
            src="/assets/images/travelthru.svg"
            alt="travelthru"
            width={160}
            height={25}
            quality={100}
            className="h-[25px] w-[106.6px] md:w-[160px] object-contain"
          />

          <img
            className="absolute -top-1 lg:-top-3 -left-2 scale-x-[-1] w-4 h-4 lg:w-6 lg:h-6"
            src={
              "https://travelthru.com/cdn-cgi/imagedelivery/wZpbJM3t8iED5kIISxeUgQ/hatpng/public"
            }
            alt="hat"
          />
        </a>
      )}
    </div>
  );
}

export default HeaderLogo;
